<template>
  <bar-chart style="height: 280px;" class="m-3" :chartdata="data" :options="options"></bar-chart>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  props: ["competenciaIni", "competenciaFim", "trabalho", "percentual"],
  components: {
    "bar-chart": () => import("../../../components/bar-chart.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    resource: function () {
      return this.apiResource(
        `/v1/timesheet/${this.clientId}/timesheetProjeto`
      );
    },
    data: function () {
      var totalHoras = this.relatorio
        .map((r) => r.horas)
        .reduce((a, b) => a + b, 0);

      return this.dataToChart(
        this.relatorio.map((r) => {
          if (this.percentual) {
            r.horas = (r.horas / totalHoras) * 100;
          }
          return r;
        }),
        "Horas",
        "projeto",
        "horas",
        4
      );
    },
    options: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        elements: { line: { tension: 0.000001 } },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              //return this.abbreviateNumber(tooltipItem.value * 1);
              //return Math.round(tooltipItem.value);
              if(this.percentual){
                var formatter = new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 1,
                });
                return formatter.format(tooltipItem.value);

              }
              return this.$options.filters.toDouble(
                this.percentual
                  ? tooltipItem.value
                  : Math.round(tooltipItem.value)
              );
            },
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                callback: (value) => {
                  return this.abbreviateNumber(value) + (this.percentual ? "%" : "");
                },
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          filler: { propagate: false },
          "samples-filler-analyser": { target: "chart-analyser" },
        },
      };
    },
  },
  watch: {
    competenciaIni: function () {
      this.loadDebounce();
    },
    competenciaFim: function () {
      this.loadDebounce();
    },
    clientId: function () {
      this.loadDebounce();
    },
    trabalho: function () {
      this.loadDebounce();
    },
  },
  mounted: function () {
    this.load();
    this.loadDebounce = _.debounce(
      () => {
        this.load();
      },
      100,
      { maxWait: 300 }
    );
  },
  methods: {
    load: function () {
      this.resource
        .get({
          query: `competenciaIni=${this.competenciaIni}&competenciaFim=${this.competenciaFim}&trabalho=${this.trabalho}`,
        })
        .then((response) => {
          if (!response.error) {
            this.relatorio = response[0];
          }
        });
    },
    abbreviateNumber: function (value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", " mil", " mi", " bi", " tri"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 3; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 3) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(2);
        var formatter = new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
        newValue = formatter.format(shortValue) + suffixes[suffixNum];
      }
      return newValue;
    },
  },
  data: function () {
    return {
      relatorio: [],
      loadDebounce: null,
    };
  },
};
</script>